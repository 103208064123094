<template>
    <div>
        <div style="text-align: center">
            <h3>Import Kontak</h3>
        </div>
        <br />
        <div class="tab-wrapper">
            <div class="atbd-tab tab-horizontal">
                <ul class="nav nav-tabs vertical-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: page.one }" v-on:click="changePage('one')">Import
                            Excel</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: page.two }" v-on:click="changePage('two')">Import Dari
                            Device</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: page.three }" v-on:click="changePage('three')">Import Dari
                            Group Whatsapp</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1" role="tabpanel"
                        aria-labelledby="tab-v-1-tab">
                        <form v-on:submit.prevent="addContact">
                            <label for="">File CSV*</label>
                            <input type="file" class="form-control" name="file" @change="readFile" accept=".csv"
                                required />
                            <br>
                            <p>
                                File contoh dapat diunduh
                                <a style="display: contents" href="/excel/contohxlsx.xlsx" download>
                                    disini
                                </a>, kemudian <b>save as</b> dengan format csv
                            </p>
                            <p>
                                <b>
                                    <u>
                                        Format Nomor Telepon CSV Harus
                                        Menggunakan Kode Negara
                                    </u>
                                </b>
                            </p>
                            <br />
                            <label>Masukkan Kontak Ke Group</label>
                            <select v-model="form.group" class="form-control">
                                <option :value="null_value" selected disabled>
                                    == PILIH GROUP ==
                                </option>
                                <option v-for="option in groupdd" :key="option.id" :value="option.id">{{ option.name }}
                                    [ID: {{ option.id }}]</option>
                            </select>
                            <br />
                            <button v-if="csvParseLoading"
                                class="btn btn-spinner btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                                disabled>
                                <div class="spinner-border spinner" role="status"></div>
                                Sedang memproses data csv
                            </button>
                            <button v-else-if="!csvImportReady && !csvParseLoading" class="btn btn-danger btn-block"
                                disabled>
                                Silahkan Upload CSV Terlebih Dahulu
                            </button>
                            <button v-else-if="csvImportReady && !csvParseLoading" type="submit"
                                class="btn btn-primary btn-block">
                                Upload
                            </button>
                            <br>
                            <div v-if="csvImportReady">
                                <h5 class="text-center">== PREVIEW ==</h5>
                                <ImportPreview v-if="csvImportReady" :rows='importData' />
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" :class="{ 'show active': page.two }" role="tabpanel"
                        aria-labelledby="tab-v-2-tab">
                        <form action="" method="post">
                            <label for="">Device*</label>
                            <select v-model="importContact.device" class="form-control" required>
                                <option value="" selected disabled>== PILIH DEVICE ==</option>
                                <option v-for="d in device" :key="d.id" :value="d.id">
                                    {{ d.name + " (" + d.status + ")"}}</option>
                            </select>
                            <br />
                            <label>Masukkan Kontak Ke Group</label>
                            <select v-model="importContact.group" class="form-control">
                                <option :value="null_value" selected disabled>
                                    == PILIH GROUP ==
                                </option>
                                <option v-for="option in groupdd" :key="option.id" :value="option.id">{{ option.name }}
                                    [ID: {{ option.id }}]</option>
                            </select>
                            <br />
                            <button v-if="importContact.loading"
                                class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block"
                                disabled>
                                <div class="spinner-border spinner" role="status"></div>
                                Loading
                            </button>
                            <button v-else type="button" class="btn btn-primary btn-block" v-on:click="syncContactGroup">
                                Sinkronisasi
                            </button>
                        </form>
                    </div>
                    <div class="tab-pane fade" :class="{ 'show active': page.three }" role="tabpanel"
                        aria-labelledby="tab-v-3-tab">
                        <form action="" method="post">
                            <label for="">Device*</label>
                            <select v-model="importGroup.device" class="form-control" :disabled="importGroup.step != 1" required>
                                <option value="" selected disabled>== PILIH DEVICE ==</option>
                                <option v-for="d in device" :key="d.id" :value="d.id">
                                    {{ d.name + " (" + d.status + ")"}}</option>
                            </select>
                            <br />
                            <div v-if="importGroup.step == 1">
                                <button v-if="importGroup.loading"
                                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block"
                                    disabled>
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </button>
                                <button v-else type="button" class="btn btn-primary btn-block" v-on:click="loadDeviceGroup">
                                    Selanjutnya
                                </button>
                            </div>
                            <div v-else>
                                <label for="">Group*</label>
                                <select v-model="importGroup.selectedGroup" class="form-control">
                                    <option :value="null_value" selected disabled>
                                        == PILIH GROUP ==
                                    </option>
                                    <option v-for="option in importGroup.loadedGroup" :key="option.id" :value="option.id">{{ option.name }}
                                        [ID: {{ option.id }}]</option>
                                </select>
                                <br />
                                <label>Masukkan Kontak Ke Group</label>
                                <select v-model="importGroup.group" class="form-control">
                                    <option :value="null_value" selected disabled>
                                        == PILIH GROUP ==
                                    </option>
                                    <option v-for="option in groupdd" :key="option.id" :value="option.id">{{ option.name }}
                                        [ID: {{ option.id }}]</option>
                                </select>
                                <br />
                                <button v-if="importGroup.loading"
                                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block"
                                    disabled>
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </button>
                                <button v-else type="button" class="btn btn-primary btn-block" v-on:click="syncGroup">
                                    Sinkronisasi
                                </button>
                                <button class="btn btn-danger btn-block" v-on:click="cancelImportGroup">
                                    Batal
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ImportPreview from "@/components/forms/contact/ImportPreview.vue";

    export default {
        components: {
            ImportPreview,
        },
        props: ["groupdd"],
        data() {
            return {
                page: {
                    one: true,
                    two: false,
                    three: false
                },
                importData: [],
                form: {
                    name: "",
                    number: "",
                    variable: [],
                    group: null,
                },
                null_value: null,
                csvParseLoading: false,
                csvImportReady: false,
                device: [],
                importContact: {
                    loading: false,
                    device: "",
                    group: null
                },
                importGroup: {
                    step: 1,
                    loading: false,
                    device: "",
                    loadedGroup: [],
                    selectedGroup: null,
                    group: null
                }
            };
        },
        mounted() {
            this.getDevice()
        },
        methods: {
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async loadDeviceGroup() {
                if(!this.importGroup.device){
                    this.$toast.error("Silahkan pilih device terlebih dahulu") 
                    return
                }

                this.importGroup.loading = true
                let res = await this.$store.dispatch("contact_load_group_device", {
                    id: parseInt(this.importGroup.device)
                });
                
                this.importGroup.loadedGroup = res.data.data.groups

                this.importGroup.step++
                this.importGroup.loading = false
            },
            async cancelImportGroup(){
                this.importGroup.device = ""
                this.importGroup.step--
            },
            async syncGroup() {
                this.importGroup.loading = true
                //GET CONTACT FROM DEVICE
                let res = await this.$store.dispatch("contact_load_group_participants", {
                    id: parseInt(this.importGroup.device),
                    jid: this.importGroup.selectedGroup
                });
                let contacts = res.data.data.contacts ?? []
                let bulkContact = []

                for (let i = 0; i < contacts.length; i++) {
                    const element = contacts[i];

                    if (!element.id) continue

                    if(!element.name) element.name = element.id

                    bulkContact.push({
                        name: element.name,
                        number: element.id,
                    })
                }

                try {
                    let response = await this.$store.dispatch("contact_create_bulk", {
                        group: parseInt(this.importGroup.group),
                        data: bulkContact
                    });
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "import_contact");
                    this.$root.$emit("refresh_table", true);
                    this.importGroup.loading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async syncContactGroup() {
                this.importContact.loading = true
                //GET CONTACT FROM DEVICE
                let res = await this.$store.dispatch("contact_load_from_device", {
                    id: parseInt(this.importContact.device)
                });
                let contacts = res.data.data.contacts
                let bulkContact = []

                for (let i = 0; i < contacts.length; i++) {
                    const element = contacts[i];

                    if (element.is_group) continue
                    if (!element.name || !element.number) continue

                    bulkContact.push({
                        name: element.name,
                        number: element.number,
                    })
                }

                try {
                    let response = await this.$store.dispatch("contact_create_bulk", {
                        group: parseInt(this.importContact.group),
                        data: bulkContact
                    });
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "import_contact");
                    this.$root.$emit("refresh_table", true);
                    this.importContact.loading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async readFile() {
                let file = event.target.files[0];
                if (file.type != "text/csv") {
                    this.$toast.error("File harus .csv")
                    return
                }

                this.importData = []
                this.csvImportReady = false
                this.csvParseLoading = true

                try {
                    let result = await this.papaParseAsync(file);
                    await this.arrayToData(result)

                    if (this.importData.length != 0) this.csvImportReady = true
                } catch (err) {
                    this.$toast.error(err);
                }
                this.csvParseLoading = false
            },
            arrayToData(array) {
                for (let i = 1; i < array.length; i++) {
                    if(array[i][0] == "") continue
                    if(array[i][1] == "") continue

                    let variable = []
                    let varcount = 1;
                    for (let j = 2; j < 10; j++) {
                        if (array[i][j] != "" && array[i][j] != undefined) {
                            variable.push({
                                id: varcount,
                                value: array[i][j]
                            })
                        }
                        varcount++
                    }

                    this.importData.push({
                        name: array[i][0],
                        number: array[i][1],
                        variable: variable
                    })
                }
            },
            papaParseAsync(file) {
                return new Promise((resolve, reject) => {
                    this.$papa.parse(file, {
                        header: false,
                        complete(results) {
                            resolve(results.data)
                        },
                        error(err, ) {
                            reject(err)
                        }
                    })
                })
            },
            async addContact() {
                this.csvParseLoading = true
                try {
                    let response = await this.$store.dispatch("contact_create_bulk", {
                        group: this.form.group,
                        data: this.importData
                    });
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "import_contact");
                    this.$root.$emit("refresh_table", true);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.csvParseLoading = false
            },
            changePage(page) {
                this.page.one = false
                this.page.two = false
                this.page.three = false

                if (page == "one") this.page.one = true
                if (page == "two") this.page.two = true
                if (page == "three") this.page.three = true
            }
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn-spinner {
        line-height: 1rem !important;
    }

    .nav-link {
        cursor: pointer !important;
    }
</style>